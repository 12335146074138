// const logEndpoint = process.env.VUE_APP_TELEPORT_LOG_URL;
import axios from 'axios';
import { snakeCaseKeys } from '@motionelements/string-utils';
import { getMediaTypeGroup } from '@motionelements/core/src/services/catalog.service.js';
import { isGtmEnabled, trackGtmEvent } from '@motionelements/core/src/helpers/gtm.js';

const eventEndpoint = process.env.VUE_APP_TELEPORT_EVENT_URL;

// use GTM template to map to GA4
function getlistName(type, product) {
  let name = type;

  // get media type group
  if (_.get(product, 'object') === 'element') {
    const mediaTypeGroup = getMediaTypeGroup(_.get(product, 'mediaType'));
    if (mediaTypeGroup) {
      name += ` ${mediaTypeGroup}`;
    }
  }
  // else if (product.object === 'collection') {
  //   name += ' collection';
  // }

  return name;
}

function toGa4EcommerceProductData(product) {
  if (!product) {
    return {};
  }

  const data = {
    item_id: _.get(product, 'id') || _.get(product, 'sku'),
    item_name: _.get(product, 'name'),
    price: _.get(product, 'priceUsd') || _.get(product, 'credits'),
    currency: 'USD',
  };

  let productCategory = product.type;

  if (product.object === 'element') {
    switch (product.mediaType) {
      case 'photo_vr':
        productCategory = 'photo/vr';
        break;
      case 'animation':
        productCategory = 'video/animation';
        break;
      case 'video_live':
        productCategory = 'video';
        break;
      case 'video_vr':
        productCategory = 'video/vr';
        break;
      default:
        productCategory = product.mediaType;
    }
  }

  data.item_category = productCategory;

  if (product.object === 'element' || product.object === 'collection') {
    const artistUsername = _.get(product, 'artist.username');
    if (artistUsername) {
      data.item_brand = _.get(product, 'artist.username');
    }
  }

  return data;
}

function toGa4EcommerceOrderLineItemData(item) {
  const product = _.get(item, 'product', null);
  const data = toGa4EcommerceProductData(product);
  // replace with sold price from order line item
  data.price = _.get(item, 'price.amountUsd');
  data.currency = 'USD';
  // add purchased variant
  data.variant = _.get(item, 'sku');
  return data;
}

function toGa4EcommerceOrderData(order) {
  return {
    currency: 'USD',
    items: _.get(order, 'lines', []).map(item => (toGa4EcommerceOrderLineItemData(item))),
    tax: _.get(order, 'vat.amountUsd', 0),
    transaction_id: _.get(order, 'id'),
    value: _.get(order, 'netTotal.amountUsd', 0),
  };
}

function resetEcommerceData() {
  if (typeof window.dataLayer !== 'undefined') {
    // console.log('Clear ecommerce');
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  }
}

function toGa4EventName(eventName) {
  switch (eventName) {
    case 'checkout_started':
      return 'begin_checkout';
    case 'order_completed':
      return 'purchase';
    case 'order_refunded':
      return 'refund';
    case 'product_favorited':
      return 'add_to_wishlist';
    case 'product_added_to_cart':
      return 'add_to_cart';
    case 'product_removed_from_cart':
      return 'remove_from_cart';
    case 'signed_in':
      return 'login';
    case 'signed_up':
      return 'sign_up';
    default:
      return eventName;
  }
}
// https://developers.google.com/tag-manager/enhanced-ecommerce
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
const trackGtm = (eventAction, data, vue) => {
  if (!isGtmEnabled(vue)) {
    return false;
  }

  // console.log('trackGtm', eventAction, data);

  try {
    if ([
      // product events
      'product_searched', // view_item_list
      'product_selected', // select_item
      'product_viewed', // view_item
      'product_favorited', // add_to_wishlist
      'product_sample_downloaded',
      'product_added_to_cart', // add_to_cart
      'product_removed_from_cart', // remove_from_cart
      'product_shared', // share
      'product_licensed',
      'product_downloaded',
      'checkout_started',
    ].includes(eventAction)) {
      const products = _.get(data, 'products') || [_.get(data, 'product')];

      if (products) {
        const currencyCode = 'USD';

        resetEcommerceData();

        switch (eventAction) {
          // impressions
          // @todo
          case 'product_searched': {
            const listName = getlistName('search', products);
            const searchKeyword = _.get(data, 'search.params.searchKeyword');
            if (searchKeyword) {
              trackGtmEvent(vue, {
                event: 'search',
                ecommerce: {
                  search_term: searchKeyword,
                },
              });
            }
            trackGtmEvent(vue, {
              event: 'view_item_list',
              ecommerce: {
                currencyCode: currencyCode,
                items: products.map((x, index) => ({
                  ...toGa4EcommerceProductData(x),
                  item_list_name: listName,
                  index: index,
                })),
              },
            });
            break;
          }

          // case 'product_selected': {
          case 'product_licensed':
          case 'product_downloaded':
          case 'product_sample_downloaded': {
            trackGtmEvent(vue, {
              event: toGa4EventName(eventAction),
              ecommerce: {
                items: products.map(product => (toGa4EcommerceProductData(product))),
              },
            });
            break;
          }
          /*
                 // view events

                 case 'product_viewed':
                   trackGtmEvent(vue, {
                     event: eventAction,
                     ecommerce: {
                       currencyCode: currencyCode,
                       detail: {
                         // actionField: {
                         //   list: 'action field listsss',
                         // },
                         products: [toGaEnhancedEcommerceProductData(product)],
                       },
                     },
                   });
                   break;
                        */

          case 'product_added_to_cart':
          case 'product_removed_from_cart':
          case 'product_favorited': {
            // console.log(eventAction, products);
            trackGtmEvent(vue, {
              event: toGa4EventName(eventAction),
              ecommerce: {
                items: products.map(product => (toGa4EcommerceProductData(product))),
              },
            });
            break;
          }

          case 'checkout_started': {
            trackGtmEvent(vue, {
              event: toGa4EventName(eventAction),
              ecommerce: {
                currencyCode: currencyCode,
                items: products.map((x, index) => ({
                  ...toGa4EcommerceProductData(x),
                  index: index,
                })),
              },
            });
            break;
          }
          default:
        }
      }
    } else if ([
      // order events
      'order_completed',
    ].includes(eventAction)) {
      const order = _.get(data, 'order');

      console.log(eventAction, order);

      // track for on_demand purchase and subscription
      if (['on_demand', 'subscription'].includes(_.get(order, 'type'))
          && _.get(order, 'status') === 'completed'
      ) {
        resetEcommerceData();

        trackGtmEvent(vue, {
          event: toGa4EventName(eventAction),
          ecommerce: toGa4EcommerceOrderData(order),
        });
      }
    } else if ([
      // account events
      'signed_up', // sign_up
      'signed_in', // login
      // 'account_activated', // account activated
    ].includes(eventAction)) {
      trackGtmEvent(vue, {
        event: toGa4EventName(eventAction),
      });
    }
  } catch (e) {
    // eat errors
    console.error(e);
  }
};

// private
const handleEvent = (eventAction, postData, vue) => {
  // var self = this;
  try {
    // make sure all eventCode as snake_case
    // var eventCode = _.snakeCase(eventName);

    // log it
    // skip
    // handleLog(eventCode);

    // console.log('teleport.handleEvent1', eventCode, postData);

    switch (eventAction) {
      case 'search_suggestion_listed':
      case 'search_suggestion_selected':
        postData = snakeCaseKeys(postData);
        console.log('skip teleport event 3', eventAction, postData);
        return true;
      default:
        break;
    }

    trackGtm(eventAction, postData, vue);

    if (process.env.VUE_APP_ENV === 'development') {
      // console.log('TELEPORT.event (skip for DEVELOPMENT env)', eventAction, postData);
      return true;
    }

    switch (eventAction) {
      case 'signed_in':
      case 'signed_up':
        // console.log('skip teleport event 4', eventAction);
        return true;
      default:
        break;
    }

    // get google clientId
    var clientId = '';

    var ga = window.ga;
    if (typeof ga !== 'undefined') {
      if (typeof ga.getAll !== 'undefined') {
        clientId = ga.getAll()[0].get('clientId');
      }
    }

    // ga(function (tracker) {
    //
    //     var clientId = tracker.get('clientId');

    var getData = {};

    getData.event = eventAction;
    getData.cid = clientId;

    var data = {};

    if (typeof window.dataLayer !== 'undefined') {
      data = window.dataLayer;

      getData.mid = _.get(data[3], 'userId', '');
      getData.aid = _.get(data[3], 'contributorId', '');
      getData.hl = _.get(data[3], 'language', '');
      getData.cc = _.get(data[3], 'countryCode', '');
      // getData.signature = _.get(data[3], 'eventSignature', '');
      // getData.time = _.get(data[3], 'timestamp', '');

      // getData.mid = (typeof data[3].userId !== 'undefined') ? data[3].userId : '' ;
      // getData.aid = (typeof data[3].contributorId !== 'undefined') ? data[3].contributorId : '' ;
      // getData.hl = (typeof data[3].language !== 'undefined') ? data[3].language : '' ;
      // getData.cc = (typeof data[3].countryCode !== 'undefined') ? data[3].countryCode : '' ;
      // getData.signature = (typeof data[3].eventSignature !== 'undefined') ? data[3].eventSignature : '' ;
      // getData.time = (typeof data[3].timestamp !== 'undefined') ? data[3].timestamp : '' ;
    }

    // for ES6
    // toQueryString: function (params) {
    //   return Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // },

    try {
      // send async, no need to wait
      axios.post(eventEndpoint, JSON.stringify(postData), {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
        withCredentials: true,
        params: getData,
      }).catch((e) => {
        console.error(e);
      });
    } catch (e) {
      console.error('Teleport error outside');
      console.error(e);
    }
  } catch (e) {
    console.log('handleEvent - error');
    console.log(e);
  }
};

// @depreacted, to remove
export function log(eventName) {
  console.log('teleport log @depreacted : ', eventName);
  // // var self = this;
  // // var eventCode = _.snakeCase(eventName).toLowerCase().split(' ').join('_');
  // // var eventCode = _.snakeCase(eventName);
  // var eventCode = eventName;

  // const ga = window.ga;
  // if (typeof window.ga !== 'undefined' && ga.create) {
  //   ga(() => {
  //     // wait for ga to load to get clientId
  //     handleLog(eventCode);
  //   });
  // } else {
  //   handleLog(eventCode);
  // }
}

export const event = (eventAction, postData, vue) => {
  // var self = this;
  // console.log('teleport.event', process.env.VUE_APP_ENV, eventName, postData);

  // console.log('teleport.event', eventName, postData);
  // track gtm event

  function isMember() {
    if (typeof window.dataLayer !== 'undefined') {
      return _.get(window.dataLayer[0], 'userId', '');
    }
    return false;
  }
  const ga = window.ga;

  // if (typeof window.ga !== 'undefined' && ga.create) {
  if (!isMember() && typeof window.ga !== 'undefined') {
    // wait for ga to load to get clientId
    ga(() => {
      handleEvent(eventAction, postData, vue);
    });
  } else {
    handleEvent(eventAction, postData, vue);
  }
};

import lodashGet from 'lodash/get';
import {
  changeCurrency, changeUiMode, setUiMode,
  // getInitialUiMode,
} from '@motionelements/core/src/services/account.service';
import { changeLanguage } from '@motionelements/core/src/services/language.service.js';
import * as accountApi from '@motionelements/core/src/api/account';

import { loadFonts } from '@motionelements/core/src/services/app.service';
import { listCurrencies } from '@motionelements/core/src/api/app.js';


function initLanguage(state, languageCode, commit) {
  const html = document.documentElement;
  // set the html tag to local lang
  html.setAttribute('lang', languageCode);

  // console.log('init language', languageCode);
  if (!state.languageFontsLoaded.includes(languageCode)) {
    // console.log('init language', 'loadFonts', languageCode);

    loadFonts(languageCode);
    commit('setLanguageFontsLoaded', languageCode);
  } else {
    // console.log('init language', 'already loaded Fonts', languageCode);
  }
}

const defaultMemberState = {
  roles: [],
  credits: 0,
  coins: null,
  studioAiSubscriptionType: null,
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  emailConfirmed: null,
  emailVerified: null,
  country: '',
  currency: null,
  language: 'en',
  avatar: {
    url: null,
  },
  registeredAt: '',
  organization: {
    id: 0,
    name: '',
    legalName: '',
  },
  organizationRole: '',
  subscriptions: [],
  freeDownloads: {
    remaining: null,
    interval: 'week',
  },
  billingDetails: null,
  artistDetails: null,
  payout: null,
  payoutMethod: null,
  heroLevel: 0,
  //
  status: '',
};

const state = {
  isLoggedIn: false,
  isMember: false,
  isArtist: false,
  isReseller: false,
  isAdmin: false,
  isOrganization: false,
  isInternal: false,
  isLoaded: false,
  language: 'en',
  currencyCode: null, // final
  jwt: {
    id: null,
  },
  // ga: {
  //   id: getGaClientId(),
  // },
  member: defaultMemberState,
  preferences: {
    ui: {
      mode: null,
    },
  },
  // array of fontsLoaded
  languageFontsLoaded: [],
  // for account page
  // subscriptions: [],
  currencies: [],
  //
  loading: false,
  isError: false,
};

const getters = {
  payoutObject(state) {
    return { ..._.get(state.member, 'payout', {}), ..._.get(state.member, 'payoutMethod', {}) };
  },
  payoutMethodStatus(state) {
    return _.get(getters.payoutObject(state), 'status', '');
  },
  isAccountActivated(state) {
    return state.member.emailVerified;
  },
  isLoggedInButUnactivated(state) {
    return state.isLoggedIn && !state.member.emailVerified;
  },
  isError(state) {
    return !!state.error;
  },
  isAffiliate(state) {
    return !!_.get(state.member, 'affiliate.code');
  },
  isMember: state => state.isMember,
  isArtist: state => state.isArtist,
  isReseller: state => state.isReseller,
  isAdmin: state => state.isAdmin,
  isOrganization: state => state.isOrganization,
  isResellerCustomer: state => _.get(state, 'member.roles', []).includes('reseller_customer'),
  // final, lowercase
  languageCode: state => state.language,
  // final, uppercase
  currencyCode: state => state.currencyCode
      || _.get(state.currencies.find(x => x.default === true), 'code'), // get default, do not set USD
  // get currency object
  currency: (state, getters) => state.currencies.find(x => x.code === getters.currencyCode),
  isFontLoaded: (state) => {
    if (state.language) {
      return !!state.languageFontsLoaded.find(v => v.language === state.language);
    }
    return false;
  },
  freeDownloadsRemaining: state => state.member.freeDownloads.remaining,
  hasUnlimitedSubscription: state => Array.isArray(state.member.subscriptions) && state.member.subscriptions.includes('unlimited'),
  getDisplayName: (state) => {
    if (!state.isMember) {
      return '';
    }
    const member = state.member;
    if (member.username) {
      return member.username;
    }
    if (member.firstName) {
      return member.firstName;
    }
    if (member.lastName) {
      return member.lastName;
    }
    return member.email.split('@')[0];
  },
  friendlyName: (state) => {
    if (!state.isMember) {
      return '';
    }
    const member = state.member;

    // prefer last name
    if (['ja'].includes(state.language)) {
      if (member.lastName) {
        return member.lastName;
      }
    } else {
      if (member.firstName) {
        return member.firstName;
      }

      if (member.lastName) {
        return member.lastName;
      }
    }

    return member.email.split('@')[0];
  },
  getAvatarUrl: (state) => {
    if (!state.isMember) {
      return null;
    }
    return state.member.avatar.url;
  },
  memberRegisteredAt: ({ member }) => member.registeredAt,
  artistRegisteredAt: (state) => _.get(state, 'member.artistDetails.registeredAt', Date.now() / 1000),
  curatorReviewStatus: state => _.get(state.member.artistDetails, 'curatorReview.status', null),
  ycidAdministeredBy(state) {
    return _.get(state.member.artistDetails, 'youtubeContentId.administeredBy', '');
  },
  getTwTaxIdValue(state) {
    const taxCountry = 'TW';
    const taxType = 'tw_vat';
    const taxId = _.get(state, 'member.billingDetails.taxIds', []).find(x => x.country === taxCountry && x.type === taxType);
    return _.get(taxId, 'value', null);
  },
};

const actions = {
  setMember({ commit }, data) {
    commit('setError', false);
    if (_.has(data, 'preferences.ui.mode')) {
      setUiMode(lodashGet(data.preferences, 'ui.mode', 'light'));
    }
    commit('setMember', data);
  },
  refreshCredits({ commit }) {
    return accountApi.getAccount()
      .then((response) => {
        const data = response.data.data;

        if (data) {
          console.log('setMemberCredits', data);
          commit('setMemberCredits', {
            credits: data.credits,
            creditsDetails: data.creditsDetails,
          });
        }
      });
  },
  setCoins({ commit }) {
    accountApi.getCoinsBalance()
      .then((response) => {
        const coins = _.get(response, 'data.data.balance');
        const type = _.get(response, 'data.data.type');
        commit('setCoins', coins);
        commit('setSubscriptionType', type);
      });
  },
  // logout or expired
  clearMember({ commit }) {
    commit('clearMember');
  },
  getBillingDetails({ commit }) {
    accountApi.getAccount({
      'fields[member]': 'billing_details',
    }).then((data) => {
      commit('setBillingDetails', _.get(data.data.data, 'billingDetails'));
    });
  },
  setCurrencyCode({ commit }, currencyCode) {
    if (currencyCode) {
      currencyCode = currencyCode.toUpperCase();
      if (currencyCode !== state.currencyCode) {
        commit('setCurrencyCode', currencyCode);
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  changeCurrency({ state, dispatch }, currencyCode) {
    console.log('changeCurrency', currencyCode);
    if (currencyCode && (currencyCode !== state.currencyCode)) {
      const currency = state.currencies.find(o => o.code === currencyCode);
      dispatch('blocker/show', { message: currency ? currency.name : currencyCode }, { root: true });
      changeCurrency(currencyCode);
    }
  },
  setLanguage({ state, commit }, languageCode) {
    if (languageCode !== state.language) {
      commit('setLanguage', languageCode);
    }
    initLanguage(state, languageCode, commit);
  },
  changeLanguage({ state, rootState, dispatch }, languageCode) {
    console.log('changeLanguage', languageCode);
    if (languageCode && (languageCode !== state.language)) {
      const supportedLanguages = rootState.app.languages;
      const newLanguage = supportedLanguages.find(x => x.code === languageCode);
      if (newLanguage) {
        // valid language code
        dispatch('blocker/show', { message: newLanguage.name }, { root: true });
        return changeLanguage(newLanguage.code);
      }
    }
    // return Promise.reject(new Error('Language is the same as current.'));
  },
  changeUiMode({ commit }, mode) {
    mode = mode === 'dark' ? 'dark' : 'light';

    changeUiMode(mode);

    commit('setUiMode', mode);
  },
  setFreeDownloadsRemaining({ commit }, payload) {
    commit('setFreeDownloadsRemaining', payload);
  },
  getFreeDownloadsRemaining({ commit }) {
    return accountApi.getFreeDownloadsRemaining().then((response) => {
      commit('setFreeDownloadsRemaining', response.data.data);
    });
  },
  getCurrencies({ commit }) {
    return listCurrencies().then((response) => {
      commit('setCurrencies', response.data.data);
      return response;
    });
  },
};

const mutations = {
  setCurrencyCode: (state, currencyCode) => {
    state.currencyCode = currencyCode;
  },
  setLanguage: (state, languageCode) => {
    state.language = languageCode;
    state.member.language = languageCode;
  },
  setUiMode: (state, value) => {
    state.preferences.ui.mode = value;
  },
  setJwtId(state, jwtId) {
    state.jwt.id = jwtId || null;
  },
  setMember(state, data) {
    // make sure roles is array
    if (_.has(data, 'roles') && !Array.isArray(data.roles)) {
      data.roles = [];
    }

    state.member = { ...state.member, ...data };

    // derived values
    state.isLoggedIn = !!_.get(data, 'email');
    state.isMember = !!_.get(data, 'email');
    state.isArtist = !!_.get(data, 'artist');
    state.isReseller = !!_.get(data, 'reseller');
    state.isAdmin = !!_.get(data, 'admin');
    state.isOrganization = !!_.get(data, 'organization.id');
    state.isInternal = !!_.get(data, 'internal');

    if (_.has(data, 'preferences')) {
      state.preferences.ui.mode = lodashGet(data.preferences, 'ui.mode', 'light');
    }

    const currencyCode = _.get(data, 'currency');

    if (currencyCode) {
      state.currencyCode = currencyCode;
    }

    if (_.has(data, 'language')) {
      state.language = data.language;
    }

    state.isLoaded = true;
  },
  setJwtMember(state, data) {
    // console.log('setMember', data);

    // make sure roles is array
    if (_.has(data, 'roles') && !Array.isArray(data.roles)) {
      data.roles = [];
    }

    state.member = { ...state.member, ...data };

    // derived values
    state.isLoggedIn = !!_.get(data, 'email');
    state.isMember = !!_.get(data, 'email');
    state.isArtist = !!_.get(data, 'artist');
    state.isReseller = !!_.get(data, 'reseller');
    state.isAdmin = !!_.get(data, 'admin');
    state.isOrganization = !!_.get(data, 'organization.id');
    state.isInternal = !!_.get(data, 'internal');

    if (_.has(data, 'preferences')) {
      state.preferences.ui.mode = lodashGet(data.preferences, 'ui.mode', 'light');
    }

    const currencyCode = _.get(data, 'currency');

    if (currencyCode) {
      state.currencyCode = currencyCode;
    }

    if (_.has(data, 'language')) {
      state.language = data.language;
    }
  },
  setMemberCredits(state, data) {
    state.member.credits = data.credits;
    state.member.creditsDetails = data.creditsDetails;
  },
  setCoins(state, coins) {
    state.member.coins = coins;
  },
  setSubscriptionType(state, type) {
    state.member.studioAiSubscriptionType = type;
  },
  clearMember(state) {
    state.isLoggedIn = false;
    state.member = defaultMemberState;
  },
  setFreeDownloadsRemaining: (state, downloads) => {
    state.member.freeDownloads.remaining = downloads;
  },
  setBillingDetails(state, data) {
    state.member.billingDetails = data;
  },
  setCurrencies: (state, data) => {
    state.currencies = Object.freeze(data);
  },
  // setIsLoaded: (state, value) => {
  //   state.isLoaded = !!value;
  // },
  setAvatarUrl(state, url) {
    state.member.avatar.url = url;
  },
  resetPayout(state) {
    state.member.payout = null;
    state.member.payoutMethod = null;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, boolean) {
    state.isError = boolean;
  },
  setLanguageFontsLoaded(state, languageCode) {
    state.languageFontsLoaded.push(languageCode);
  },
  //
  setYCID(state, value) {
    state.member.artistDetails = { ...state.member.artistDetails, youtubeContentId: { registered: value } };
  },
  //
  // setCuratorReviewStatus() {},
  setMemberPayoutMethod(state, payoutMethod) {
    state.member.payoutMethod = payoutMethod;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

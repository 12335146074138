import { aiToolsIds, aiTools } from '@motionelements/core/src/helpers/ai-tools.js';

export default {
  name: 'AiToolsMixin',
  data() {
    return {
      aiToolsTranslations: {
        [aiToolsIds.VIDEO_SCRIPTWRITER]: {
          title: this.$t('ai_tools.video_scriptwriter.heading'),
          desc: this.$t('ai_tools.video_scriptwriter.description'),
          descWbr: this.$t(this.$wbr('ai_tools.video_scriptwriter.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/video-scriptwriter.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/video-scriptwriter-m.240731.png?auto=format&w=345',
          price: null,
        },
        [aiToolsIds.FACE_SWAP]: {
          title: this.$t('ai_tools.face_swap.heading'),
          desc: this.$t('ai_tools.face_swap.description'),
          descWbr: this.$t(this.$wbr('ai_tools.face_swap.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/face-swap.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/face-swap-m.240731.png?auto=format&w=345',
          price: '5',
        },
        [aiToolsIds.BACKGROUND_REMOVER]: {
          title: this.$t('ai_tools.background_remover.heading'),
          desc: this.$t('ai_tools.background_remover.description'),
          descWbr: this.$t(this.$wbr('ai_tools.background_remover.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/background-remover.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/background-remover-m.240731.png?auto=format&w=345',
          price: '1',
        },
        [aiToolsIds.STYLE_TRANSFER]: {
          title: this.$t('ai_tools.style_transfer.heading'),
          desc: this.$t('ai_tools.style_transfer.description'),
          descWbr: this.$t(this.$wbr('ai_tools.style_transfer.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/style-transfer.240731.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/style-transfer-m.240731.png?auto=format&w=345',
          price: '5',
        },
        [aiToolsIds.IMAGE_VARIATIONS]: {
          title: this.$t('ai_tools.image_variations.heading'),
          desc: this.$t('ai_tools.image_variations.description'),
          descWbr: this.$t(this.$wbr('ai_tools.image_variations.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/image-variation.240918.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/image-variation-m.240919.png?auto=format&w=345',
          price: '5',
        },
        [aiToolsIds.VIDEO_UPSCALER]: {
          title: this.$t('ai_tools.video_upscaler.heading'),
          desc: this.$t('ai_tools.video_upscaler.description'),
          descWbr: this.$t(this.$wbr('ai_tools.video_upscaler.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/video-upscaler.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/video-upscaler-m.png?auto=format&w=345',
          price: '0',
        },
        // coming soon
        [aiToolsIds.IMAGE_TO_VIDEO]: {
          title: this.$t('ai_tools.image_to_video.heading'),
          desc: this.$t('ai_tools.image_to_video.description'),
          descWbr: this.$t(this.$wbr('ai_tools.image_to_video.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/image-to-video.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/image-to-video-m.png?auto=format&w=345',
          price: '50',
        },
        [aiToolsIds.IMAGE_GENERATOR]: {
          title: this.$t('draft.ai_tools.image_generator'),
          desc: this.$t('draft.ai_tools.image_generator_desc'),
          descWbr: this.$t(this.$wbr('draft.ai_tools.image_generator_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/image_generator.241113.png',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/image_generator-m.241113.png',
          price: '0',
        },
        [aiToolsIds.IMAGE_ENHANCER]: {
          title: this.$t('draft.ai_tools.image_enhancer'),
          desc: this.$t('draft.ai_tools.image_enhancer_desc'),
          descWbr: this.$t(this.$wbr('draft.ai_tools.image_enhancer_desc')),
          img: 'https://static.moele.me/img/studio-ai/tools/image_enhancer.241113.png',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/image_enhancer-m.241113.png',
          price: '0',
        },
        [aiToolsIds.MUSIC_DURATION_ADJUSTER]: {
          title: this.$t('ai_tools.music_duration.heading'),
          desc: this.$t('ai_tools.music_duration.description'),
          descWbr: this.$t(this.$wbr('ai_tools.music_duration.description')),
          img: 'https://static.moele.me/img/studio-ai/tools/music-duration-adjuster.png?auto=format&w=267',
          imgResponsive: 'https://static.moele.me/img/studio-ai/tools/music-duration-adjuster-m.png?auto=format&w=345',
          price: '5',
        },
      },
    };
  },
  computed: {
    aiToolsIds() {
      return aiToolsIds;
    },
    aiTools() {
      return aiTools
        .filter(({ released }) => released)
        .map(tool => ({
          ...tool,
          ...this.aiToolsTranslations[tool.id],
        }));
    },
    aiToolsGrouped() {
      const aiToolsGrouped = [
        {
          id: 'video',
          title: this.$t('term.video'),
        },
        {
          id: 'image',
          title: this.$t('term.image'),
        },
        {
          id: 'music',
          title: this.$t('term.music'),
        },
      ];
      return aiToolsGrouped.map(group => ({
        ...group,
        scrollId: `media-type-${group.id}`,
        tools: this.aiTools.filter(aiTool => aiTool.mediaType === group.id),
      }));
    },
    comingSoonAiTools() {
      return aiTools
        .filter(({ released }) => !released)
        .map(tool => ({
          ...tool,
          ...this.aiToolsTranslations[tool.id],
        }));
    },
  },
};
